<template>
  <div>
    <h1 class="uk-text-left"><router-link :to="{ name: 'Users' }"  style="text-decoration: none;"><span uk-icon="icon: chevron-left; ratio: 2"></span>{{pageTitle}}</router-link></h1>
    <hr />

    <div v-if="!isLoading" class="uk-grid-divider uk-grid-medium" uk-grid>
      <div class="uk-width-expand@m">
        <baseTable
          v-if="user.permissions"
          :tableHeaders="tableHeaders"
          :totalCount="user.permissions.length"
        >
          <tr 
            v-for="(property, i) in properties"
            :key="i"
          >
            <td class="uk-text-truncate uk-width-1-2"> <router-link :to="{ name: 'Collections', params: { id: orgId, propertyId: property.id, propertyName: property.i18nName.localized.value } }">{{ property.i18nName.localized.value }}  </router-link> </td>

            <td>
              <div class="uk-button-group">
                <button 
                  class="uk-button uk-button-default" 
                  :class="(propertyPermissions[property.id] === Permissions.NONE) || !(propertyPermissions[property.id]) ? 'uk-active' : ''" 
                  type="button" 
                  @click="updateUserPermission(property.id, Permissions.NONE, property.i18nName.localized.value)"
                >None</button>
                <button 
                  class="uk-button uk-button-default" 
                  :class="(propertyPermissions[property.id] === Permissions.VIEWER) ? 'uk-active' : ''" 
                  type="button" 
                  @click="updateUserPermission(property.id, Permissions.VIEWER, property.i18nName.localized.value)"
                >Viewer</button>
                <button 
                  class="uk-button uk-button-default" 
                  :class="(propertyPermissions[property.id] === Permissions.CONTENT_MANAGER) ? 'uk-active' : ''" 
                  type="button" 
                  @click="updateUserPermission(property.id, Permissions.CONTENT_MANAGER, property.i18nName.localized.value)"
                >Content Manager</button>
                <button 
                  class="uk-button uk-button-default" 
                  :class="(propertyPermissions[property.id] === Permissions.ANALYTICS_MANAGER) ? 'uk-active' : ''" 
                  type="button" 
                  @click="updateUserPermission(property.id, Permissions.ANALYTICS_MANAGER, property.i18nName.localized.value)"
                >Analytics Manager</button>
                <button 
                  class="uk-button uk-button-default" 
                  :class="(propertyPermissions[property.id] === Permissions.ADMIN) ? 'uk-active' : ''" 
                  type="button" 
                  @click="updateUserPermission(property.id, Permissions.ADMIN, property.i18nName.localized.value)"
                >Admin</button>
              </div>
            </td>
          </tr>
        </baseTable>
      </div>
  
      <div class="uk-width-1-3 uk-text-left">
        <h3>{{ $t('Pages.UserPermissions.Information') }}</h3>
        <UserGravatar size="100" :email="user.email"/>
        <ul v-if="user.id" class="uk-list uk-list-striped">
          <li>ID: {{ userId }}</li>
          <li>{{ $t('Generic.Labels.FirstName') }}: {{ user.firstName }}</li>
          <li>{{ $t('Generic.Labels.LastName') }}: {{ user.lastName }}</li>
          <li>{{ $t('Generic.Labels.Email') }}: {{ user.email }}</li>
          <li>{{$t('Pages.Users.Joined')}}: {{ $d( new Date(user.registered), 'long') }}</li>
          <li>
            <span v-if="user.active" class="uk-label uk-label-success">{{$t('Generic.Labels.Active')}}</span>
            <span v-else class="uk-label uk-label-danger">{{$t('Generic.Labels.Inactive')}}</span>
          </li>
        </ul>
        <div v-if="!user.active && loginUser.role === 'ADMIN'">
          <button v-if ="!saving" class="uk-button uk-button-primary" @click="resendOrgInvite()">{{$t('Pages.Users.ResendVerificationEmail')}}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
    <div v-else>
      <span uk-spinner></span>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import Notification from '@/components/util/Notification'
import BaseTable from '@/components/tables/BaseTable.vue';
import UserGravatar from '@/components/images/UserGravatar.vue';
import { Permissions } from '../../utils/enums';

export default {
  name: 'OrgUserProfile',
  components: {
    BaseTable,
    UserGravatar
  },
  data() {
    return {
      user: {
        firstName: undefined
      },
      Permissions,
      properties: {},
      propertyPermissions: {},
      orgId: this.$route.params.id,
      isLoading: false,
      saving: false
    }
  },
  computed: {
    ...mapState({
      loginUser: state => state.venom.user || {}
    }),
    userId: function() {
      return this.$route.params.userId;
    },
    pageTitle () {
      if (this.user.firstName)
        return `${this.user.firstName}'s ${this.$t('TopNavBar.Permissions')}`
      else return this.$t('Pages.UserPermissions.Title')
    },

    tableHeaders () {
      return [{
        name: this.$t('Pages.UserPermissions.Folder'), 
        class: 'uk-preserve-width'
      }, {
        name: this.$t('TopNavBar.Permissions'),
        class: 'uk-width-xlarge'
      }]
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    sortPropertyNames( a, b ) {
      if (a.property.i18nName.localized.value > b.property.i18nName.localized.value) return 1;
      if (b.property.i18nName.localized.value > a.property.i18nName.localized.value) return -1;
    },
    goBack () {
      if (this.prevRoute.name && this.prevRoute.name !== 'Login') {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'Users'})
      }
    },
    async getUserPermissions() {
      this.isLoading = true
      const { user, properties } = await this.$store.dispatch('fetchUserDetails', { orgId: this.$route.params.id, userId: this.userId });
      this.properties = properties.sort((a, b) => a.i18nName.localized.value > b.i18nName.localized.value ? 1 : -1 )
      this.isLoading = false
      if (user.permissions && user.permissions.length) {
        user.permissions.forEach(perm => {
          this.propertyPermissions[perm?.property?.id] = perm.type
        })
      }
      return user;
    },
    async updateUserPermission( property, type, propertyName ) {
      let typeTemp = this.propertyPermissions[property]
      this.propertyPermissions[property] = type
      const response = await this.$store.dispatch('setPropertyPermission', {
        orgId: this.orgId, 
        userId: this.userId,
        propertyId: property,
        type
      })
      if (response.id) {
        this.user = response;
        const permissions= this.user.permissions.filter( perm => perm.property )
        this.user.permissions = permissions?.sort( this.sortPropertyNames ) || null;
        if (this.user?.permissions) {
          Notification.showNotification( this.$t('Pages.UserProfile.UserPermUpdated'), this.$t('Pages.UserPermissions.Notification.UpdatedSuccessDesc', { name: type, property: propertyName || property }));
        } else {
          this.propertyPermissions[property] = typeTemp
          Notification.showNotification( this.$t('Pages.UserProfile.UserPermNotUpdated'), `Failed to update permission to ${type} for property ${propertyName || property}`, 'error' );
        }
      } else if (response?.includes('Insufficient')) {
        this.propertyPermissions[property] = typeTemp
        Notification.showNotification(this.$t('Pages.UserProfile.UserPermNotUpdated'), this.$t('Pages.UserPermissions.Notification.InsufficientAuth'), 'error');
      } else {
        this.propertyPermissions[property] = typeTemp
        Notification.showNotification( this.$t('Pages.UserProfile.UserPermNotUpdated'), `Failed to update permission to ${type} for property ${propertyName || property}`, 'error');
      }
    },
    async resendOrgInvite () {
      if (this.saving) return;
      this.saving = true;
      const response = await this.$store.dispatch('resendOrgInvite', { userId: this.user.id });
      this.saving = false;
      if (response.resendVerificationEmail?.user) {
        Notification.showNotification(this.$t("Pages.InviteUser.success.InviteSuccessful"), this.$t("Pages.InviteUser.success.InviteSuccessfulDesc").replace('{{email}}', this.user.email));
      } else if (response.invitedCMSUser?.id) {
        Notification.showNotification(this.$t( 'Generic.Messages.UnSuccessful'), response?.errors[0]?.message, 'error');
      }
    }
  },
  async mounted() {
    this.user = await this.getUserPermissions(); 
  }
}
</script>
